body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vcenter-item{
  display: flex;
  align-items: center;
}

.pub-image-cell {
  /*max-width: 300px;*/
}

.pub-image {
  max-height: 120px;
  object-fit: contain;
}

.pub-text-p {
  margin-bottom: 8px;
  line-height: 1.1rem;
}

.pub-text-title {
  margin-top: 10px;
  font-weight: 550;
}

.pub-row {
  padding: 7px;
  margin: 2px;
}

.pub-card {
  margin: 7px;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.social-icon{
  text-indent: 0px;
  text-align: center;
  padding: 5px;
}

.bg-middle-gray {
  background: #dddddd;
}

.color-blue {
  color: #0000ff;
}

.color-green {
  color: #00aa00;
}

.color-magenta {
  color: #ee00ee;
}

.color-gray {
  color: #888888;
}

.color-orange {
  color: #EF7F1A;
}

.blue-shadow {
  box-shadow: 0 1rem 3rem rgb(180 220 255) !important
}

.code-like-font {
  font-family: monospace;
}