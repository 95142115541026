/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

body {
    overflow-x: hidden;
}

.mypicture-container {
    text-align: center;
    text-indent: 0px !important;
}

.mypicture-container img {
    width: 200px;
    margin: 10px 0px;
}

.medium-icon svg{
    width: 30px;
    height: auto;
}

.sidebar-icons{
}
